<template>

  <div class="modal fade hch_modal" :class="propClasses" tabindex="-1" ref="modalRef">
    <div class="modal-dialog modal-dialog-scrollable" :class="{'modal-dialog-top': props.centered}">
      <div class="modal-content">

        <div class="modal-header" v-if="slots['header']">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="slots['footer']">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
  <div class="hch_modal_custom_backdrop" v-if="customBackdrop === true && bsModal && bsModal._isShown"></div>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, onMounted, ref, useSlots} from "vue";
import {watch} from "#imports";
import {useNuxtApp, useState, useRoute, useHead} from "#app";

const slots = useSlots();

const props = defineProps({
  open: Boolean,
  centered: Boolean,
  name: String,
  customBackdrop: Boolean,
  hideBackdrop: Boolean,
  'noCloseOnBackdrop': Boolean,
  'modalClass': String,
  'size': String
})

const emit = defineEmits(['hide']);
const {$bootstrap} = useNuxtApp();

const bsModal = ref(null);
const modalRef = ref(null);
const propClasses = computed(() => {
  const classList = [];
  if (props["modalClass"]) {
    classList.push(props['modalClass']);
  }
  if (props.size) {
    classList.push(`modal-${props.size}`);
  }
  if (props.customBackdrop) {
    classList.push(`custom-backdrop`);
  }
  return classList.join(' ');
});

onMounted(() => {
  bsModal.value = new $bootstrap.Modal(modalRef.value, {
    keyboard: false,
    backdrop: props.noCloseOnBackdrop ? 'static' : (props.hideBackdrop !== true)
  });
  modalRef.value.addEventListener('hidden.bs.modal', function (event) {
    closeModal();
  })
});

onBeforeUnmount(() => {
  if (bsModal.value) {
    bsModal.value.hide();
  }
})

watch(() => props.open, (val) => {
  if (bsModal.value) {
    if (val === false) {
      bsModal.value.hide();
      emit('hide');
      if(props.name) {
        setTimeout(() => {
          document.body.classList.remove('modal-' + props.name);
        }, 50);
      }
    } else {
      bsModal.value.show();
      if(props.name) {
        setTimeout(() => {
          document.body.classList.add('modal-' + props.name);
        }, 50);
      }
    }
  }
})

const closeModal = () => {
  emit('hide');
}


</script>

